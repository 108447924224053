import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../what-is-healthy-wealthy/what.css";
import ICO from  '../../images/ICO Image.svg';

function Whatabout(props) {
  return (
    <div className="about">
      <Container fluid className="custom-container">
        <Row>
          <Col md={6}>
            <div class="content">
              <h3>
                What is <span>HealthiWealthi</span>™?
              </h3>
              <p>
                If I would ask, "What's really important to you?" health and
                wealth are very likely on the very top. Health is important
                because without health we have nothing. Wealth (or financial
                security, a stable income, feeling prosperous, or whatever term
                you use) is equally vital for fulfilling your life's dreams and
                passions.
              </p>
              <p>
                At HealthiWealthi™ we believe that you should be "paid and
                rewarded for living healthy".{" "}
              </p>
              <p>
                "You never change things by fighting the existing reality. To
                change something, build a new model that makes the existing
                model obsolete." Buckminster Fuller.
              </p>
            </div>
          </Col>
          <Col md={6}>
            <div class="ico-image text-center">
              <img src={ICO} class="img-fluid" alt="image" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Whatabout;

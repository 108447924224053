import React, { useEffect } from "react";
import "../invest-in-yourself/investyourself.css";
import { Col, Container, Row } from "react-bootstrap";
import rxch from "../../images/RxHL.svg";
const InvestInYourSelf = () => {
  useEffect(() => {
    (function () {
      const second = 1000,
        minute = second * 60,
        hour = minute * 60,
        day = hour * 24;
      let today = new Date(),  dd = String(today.getDate()).padStart(2, "0"),   mm = String(today.getMonth() + 1).padStart(2, "0"),
        yyyy = today.getFullYear(),
        nextYear = yyyy + 1,
        dayMonth = "09/30/",
        birthday = dayMonth + yyyy;
    
      today = mm + "/" + dd + "/" + yyyy;
      if (today > birthday) {
        birthday = dayMonth + nextYear;
      }
      //end
    
      const countDown = new Date(birthday).getTime(),
        x = setInterval(function () {
    
          const now = new Date().getTime(),
            distance = countDown - now;
    
           document.getElementById("days").innerText = Math.floor(distance / (day))
            document.getElementById("hours").innerText = Math.floor((distance % (day)) / (hour))
            document.getElementById("minutes").innerText = Math.floor((distance % (hour)) / (minute))
            document.getElementById("seconds").innerText = Math.floor((distance % (minute)) / second);
    
          //do something later when date is reached
          if (distance < 0) {
            document.getElementById("headline").innerText = "It's my birthday!";
            document.getElementById("countdown").style.display = "none";
            document.getElementById("content").style.display = "block";
            clearInterval(x);
          }
          //seconds
        }, 0)
    }());
  }, [])

  return (
    <section className="invest-in-yourself" id="Tokenomics">
      <Container fluid className="custom-container">
        <Row>
          <Col md={12}>
            <div className="content text-center">
              <h2>
                Invest in <span>Yourself</span> and Healthcare 3.0 - Tokenomics
              </h2>
              <p>
                Tokenomics addresses the factors that impact the demand and
                supply of tokens including factors for growth-oriented token
                value.
              </p>
            </div>
          </Col>
          <Col md={5} sm={12}>
            <div className="rxhc-card">
              <div className="d-flex">
                <div className="d-flex rxhc-image">
                  <img src={rxch} className="img-fluid" alt="image" />
                </div>
                <div className="d-flex flex-column   rxhc-content">
                  <div className="d-flex">
                    <p>Healthi Wealthi</p>
                    <span className="pt-3">
                      <sub>RXHL</sub>
                    </span>
                  </div>
                  <div className="d-flex rxhc-content-counting">
                    <h2>$1,960,000</h2>
                    <span className="">Target Goals</span>
                  </div>
                </div>
              </div>
              <div className="d-flex  token-content">
                <h5 className="flex-grow-1">Tokens for Sale</h5>
                <p>25.000.000.000 RXHL</p>
              </div>
              <div className="d-flex  token-content">
                <h5 className="flex-grow-1">ICO Price</h5>
                <p>$0,001</p>
              </div>
              <div className="d-flex  token-content">
                <h5 className="flex-grow-1">Soft Cap</h5>
                <p>$0,001</p>
              </div>
              <div className="d-flex  token-content">
                <h5 className="flex-grow-1">Blockchain Technology</h5>
                <p>MATIC, ETH</p>
              </div>
              <div className="d-flex  token-content purchase-limit">
                <h5 className="flex-grow-1">Purchase Limit</h5>
                <p>$100</p>
              </div>
              <div className="launch-date">
                <p>The RXHEAL Token Launch (ICO) will start in</p>
              </div>
              <div id="countdown">
                <ul>
                  <li>
                    <span id="days"></span>
                    <p className="countdown-content">days</p>
                  </li>
                  <li>
                    <span id="hours"></span>
                    <p className="countdown-content">Hours</p>
                  </li>
                  <li>
                    <span id="minutes"></span>
                    <p className="countdown-content">Minutes</p>
                  </li>
                  <li>
                    <span id="seconds"></span>
                    <p className="countdown-content">Seconds</p>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col md={7} sm={12}>
            <div class="token-content-members">
              <h3>The RxHEAL Tokenomics</h3>
              <div class="tokenomics-para">
                <p>Token ICO Price: $0001</p>
                <p>Purchase Limit: $100</p>
                <p>Blockchain Technology: Polygon (MATIC) on Ethereum blockchain</p>
              </div>
         <a href="/login">   <button class="btn btn-danger">JOIN & INVEST</button></a> 
              <span><a href="">Get Details</a></span>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default InvestInYourSelf;

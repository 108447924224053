import React from "react";
import "../chronic-disease/disease.css";
import { Col, Container, Row } from "react-bootstrap";
const Disease = () => {
  const MissionVissionCard = [
    {
      id: 1,
      headeing: "Our Vision",
      paragraph:
        "Everyone has affordable access to Lifestyle Medicine and Health Coach practitioners.",
    },
    {
      id: 2,
      headeing: "mission-vission",
      paragraph:
        "Unlock human potential - inspire millions to live healthier, happier, longer aligned with their life's purpose guided by certified health coaches living their own dream.",
    },
  ];
  return (
    <section className="why">
      <Container fluid className="custom-container">
        <Row>
          <div class="content">
            <h3 class="text-center">
              The <span>Chronic Disease </span>Pandemic
            </h3>
          </div>
          <Col md={6}>
            <div className="content">
              <p>
                1+ billion people worldwide suffer from chronic diseases like
                heart disease, obesity, diabetes, pain, depression, anxiety
                caused by unhealthy lifestyle habits. Because healthcare is
                mainly focused on managing disease, we firmly believe a new
                innovative, scalable healthcare 3.0 platform needs to be built
                that provides easy and affordable access to Lifestyle Medicine
                and Health Coaching. Lifestyle is medicine with extensive
                research showing that the majority of chronic health issues can
                be stopped or reversed through comprehensive lifestyle
                improvements.{" "}
              </p>
            </div>
          </Col>
          <Col md={6}>
            {MissionVissionCard.map((i) => 
            <div className="mission-vission">
              <h4>{i.headeing}</h4>
              <p>{i.paragraph}</p>
            </div>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Disease;

import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import '../erorr/erorr.css';

function Erorr() {
  return <div>
    <Container>
      <Row>
      <Col md={12}>
        <div className='page-not-found'><h5>opps ! page not found</h5></div>
        </Col>
      </Row>
    </Container>
  </div>;
}

export default Erorr;

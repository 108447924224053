import React from 'react';
import Banner from '../banner/Banner';
import AnimatedHeading from '../big-plan/Bigplan';
import Disease from '../chronic-disease/Disease';
import MembershipLevel from '../membership-level/MemberShipLevel';
import Vendors from '../sponser/Sponsers';
import Whatabout from '../what-is-healthy-wealthy/What';
import EcoGraph from '../ecosystem/Ecosystem';
import InvestInYourSelf from '../invest-in-yourself/Investyourself';
import TokenAllocation from '../token-allocation/Token-allocation';
import BudgetAllocation from '../budget-allocation/Budget-allocation';
import MyTeam from '../team/Team';
import Questions from '../asked-questions/Asked-questions';
import JoinCommunity from '../join-us/JoinCommunity';
import ResponsiveTimeline from '../timeline/Timeline';
function Home(props) {
  return (
    <div>
      <Banner/>
      <Vendors/>
      <Whatabout/>
      <Disease/>
      <MembershipLevel/>
      <AnimatedHeading>
        We’ve got <span>BIG</span> plans <span>.</span>
      </AnimatedHeading>
      <EcoGraph/>
      <ResponsiveTimeline/>
      <InvestInYourSelf/>
      <TokenAllocation heading="Token Allocation"/>
      <BudgetAllocation heading="Budget Allocation"/>
      <AnimatedHeading >
        Meet the <span id="about">RXHEAL</span> team<span>.</span>
      </AnimatedHeading>
      <MyTeam  heading="Team" paragraph="The HealthiWealthi™ wellbeing and prosperity generating decentralized platform and ecosystem that rewards practitioners and members for living healthier, happier, wealthier andlonger is powered by a highly qualified and experienced team."/>
      <MyTeam heading="Advisory Board" paragraph="Our Advisory board consists of leading authorities, best-selling authors and healthcare professionals with over 1,000 years of combined practice, research, or work experience."/>
      <Questions/>
      <JoinCommunity/>
    </div>
  );
}
export default Home;
import React from "react";
import "../timeline/timeline.css";
import { Container, Row } from "react-bootstrap";
const ResponsiveTimeline = () => {
  const ResponsiveRoadMapContent = [
    {
      id: 1,
      paragraph: "Project was downloaded from the 'matrix' ⚡",
      heading: "December 2023",
    },
    {
      id: 2,
      paragraph: "Platform Design and Structure finalized",
      heading: "February  2022",
    },
    {
      id: 3,
      paragraph: "Pre-launch and supporter website with wallet completed",
      heading: "March 2022",
    },
    {
      id: 4,
      paragraph: "Platform app front & backend (Version 1.0) completed",
      heading: "June  2022",
    },
    {
      id: 5,
      paragraph: "Research (beta-test) with 500 members & 10 coaches.",
      heading: "July  2022",
    },
    {
      id: 6,
      paragraph: "Pre-Launch RXHEAL Airdrop (1 Million Token Give-Away)",
      heading: "October 2022",
    },
    {
      id: 7,
      paragraph: "Official Public Platform and Token Launch",
      heading: "November 2022",
    },
    {
      id: 8,
      paragraph: "Large Teams and Organizations join platform",
      heading: "February 2023",
    },
    {
      id: 9,
      paragraph: "School Teachers join platform",
      heading: "September  2023",
    },
    {
      id: 10,
      paragraph: "Platform opens to developers",
      heading: "December 2023",
    },
  ];
  return (
    <section className="responsive-timeline">
      <Container fluid className="custom-container">
        <Row>
          <div className="timeline">
            <ul>
              {ResponsiveRoadMapContent.map((i) => (
                <li>
                  <div className="content">
                    <p>Project was downloaded from the 'matrix' ⚡</p>
                  </div>
                  <div className="time">
                    <h4>December 2023</h4>
                  </div>
                </li>
              ))}

            </ul>
          </div>
        </Row>
      </Container>
    </section>
  );
};

export default ResponsiveTimeline;

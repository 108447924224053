import React from "react";
import '../navbar/navbar.css';
import { Container, Navbar, Nav, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from '../../images/Logo.svg';
function Navigation(props) {
  return (
    <div>

      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Whitepaper</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">

              <embed src="/assets/pdf/HealthiWealthi_White_Paper_2022.pdf"
                frameborder="0" width="100%" height="450px">
              </embed>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>


      <div className="nav-wrapper">
        <Navbar collapseOnSelect expand="lg">
          <Container fluid className="custom-container">
            <Navbar.Brand >
              <strong>
                <Link class="navbar-brand text-uppercase" to="/">
                  <img src={Logo} class="img-fluid" alt="Logo" />
                </Link>
              </strong>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="navbar ms-auto ">

                <Link className="nav-link" to='/assets/pdf/HealthiWealthi_White_Paper_2022.pdf' target='_blank'>
                  Whitepaper
                </Link>

                {/* <Link className="nav-link" to="/Roadmap">Roadmap</Link> */}
                {/* <Link className="nav-link" to="/Tokensale">Token Sale</Link> */}
                {/* <Link className="nav-link" to="/Tokenomics">Tokenomics</Link> */}
                {/* <Link className="nav-link" to="/About">About</Link> */}
                {/* <Link className="nav-link" to="/Contact">Contact</Link> */}

                <li class="nav-item">  <a class="nav-link menu-link" href="#roadmap"> Roadmap</a></li>
                <li class="nav-item">  <a class="nav-link menu-link" href="#tokenSale"> Token Sale</a></li>
                <li class="nav-item">  <a class="nav-link menu-link" href="#Tokenomics">Tokenomics</a></li>
                <li class="nav-item">  <a class="nav-link menu-link" href="#about">About</a></li>
                <li class="nav-item">  <a class="nav-link menu-link" href="#contact">Contact</a></li>


            <a href="/login">    <Button className="login-btn">Login</Button></a>


              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>















      </div>
    </div>




  );
}

export default Navigation;

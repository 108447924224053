import React from "react";
import "../token-allocation/token-allocation.css";
import { Col, Container, Row } from "react-bootstrap";
import graphImage from '../../images/Graph.svg';
import rxhlImage from '../../images/RxHL.svg';
const TokenAllocation = (props) => {
  const TokenList = [
    {
      id: 1,
      paragraph:
        "  40% will be allocated to pre-launch contributors sale and public launch members.",
    },
    {
      id: 2,
      paragraph:
        "  20% will be allocated to the company's reserve (with a 2 year vesting period with 1/24 of the reserve unlocked and used to grow the ecosystem and developments).",
    },
    {
      id: 3,
      paragraph:
        "  20% will be allocated to the founding team, advisors (2-year vesting period with a 12-month cliff with 1/12 part per month to be released to avoid fluctuations).",
    },
    {
      id: 4,
      paragraph:
        "  10% will be allocated for marketing, outreach, and partnerships.",
    },
    {
      id: 5,
      paragraph:
        "   10% will be allocated for member rewards to encourage platform growth.",
    },
  ];
  return (
    <section className="token-allocation">
      <Container fluid className="custom-container"  id="tokenSale">
        <Row>
          <h4>{props.heading}</h4>
          <Col md={6}>
            <div class="content">
              <ul class="list-unstyled">
                {TokenList.map((i) => (
                  <li>{i.paragraph}</li>
                ))}
              </ul>
            </div>
          </Col>
          <Col md={6}>
            <div class="graph">
              <div class="overlay">
                <img src={rxhlImage} class="img-fluid" alt="image" />
              </div>
              <div class="token-allocation-image text-center">
                <img src={graphImage} class="img-fluid" alt="image" />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default TokenAllocation;

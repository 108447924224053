import React from "react";
import "../join-us/join-community.css";
import { Col, Container, Row } from "react-bootstrap";
const JoinCommunity = (props) => {

    return (
        <section className="join-now">
            <Container fluid className="custom-container">
                <Row>
                   <Col md={12}>
                   <div className="join-now-content">
                            <div className="row">
                                <Col lg={9} sm={12}>
                                    <h3>Join Our Community</h3>
                                </Col>
                                <Col lg={3} sm={12}>
                                    <div className="join-now-btn">
                                        <button className="btn btn-danger">Join {<br/>} Now</button>
                                    </div>
                                </Col>
                            </div>
                        </div>
                   
                   </Col>
                </Row>
            </Container>
        </section>
    );
};
export default JoinCommunity;

import React, { Component } from "react";
import "../asked-questions/asked-questions.css";
import { Col, Container, Row } from "react-bootstrap";
const Questions = (props) => {
    const CustomAccordion = [
        { id: 1, parent: 1, headingContent:" HealthiWealthi™ makes Health & Life Coaching accessible to everyone." },
        { id: 2, parent: 2, headingContent:"We believe every human being should have their own personal health and life coach."  },
        { id: 3, parent: 3, headingContent:" The HealthiWealthi™ platform is a peer-to-peer Web 3.0 membership community that directly connects Health & Life Coaches with individuals, families & teams."  },
        { id: 4, parent: 4, headingContent:" HealthiWealthi™ is a decentralized, global and token-based platform that operates parallel to the current healthcare system allowing members to make their own decisions." },
        { id: 5, parent: 5, headingContent:"There's no intermediary that has to be paid or makes money off you (hence the low-cost membership and coaching fees)."  },
        { id: 6, parent: 6, headingContent:" Members get paid to live healthi. The more they engage, get help from their personal coach, improve their habits, experience health outcomes, the more rewards they get and the wealthier they live."  },
        { id: 7, parent: 7, headingContent:" Members are in charge of their own health and wealth which requires self-responsibility, taking actions, and consciously improving habits." },
        { id: 8, parent: 8, headingContent:"HealthiWealthi™ is private, secure, HIPAA compliant and members own their data."  },
        { id: 9, parent: 9, headingContent:" No marketing, sales, sharing of members' data is permitted."  },
        { id: 10, parent: 10, headingContent:"We believe if given the opportunity and with the help of a qualified coach, members will improve their lifestyle habits to live healthier, happier, and longer."  },
        { id: 11, parent: 11, headingContent:" We believe our well-educated members should be free to make their own health and life decisions without governments, big tech-pharma-medicine industries, or other profit-driven institutions doing it for them."  }
    ]
    const CustomList = {
        1: [
            {id:1, listcontent:"Register X either through the link provided by your coach or at www.healthiwealthi.io (usingour secure mobile phone verification)."},
            {id:2, listcontent:"Choose a membership level and pay the monthly or annual fee with your credit card (orother available payment methods in your country)."},
            {id:3, listcontent:"Your membership fee is converted into RXHEAL tokens."},
            {id:4, listcontent:"Schedule your first health coaching consultation or group class."},
            {id:5, listcontent:"Watch your token rewards increase inside your dashboard. The more you invest in your health by following your coaches guidance the more rewards you will accumulate)."},
            {id:6, listcontent:"You may join the Longevity Club (lock your token for 12-months for additional rewards)."},
        ], 
        2: [
            {id:1, listcontent:"Register Y either through the link provided by your coach or at www.healthiwealthi.io (usingour secure mobile phone verification)."},
            {id:2, listcontent:"Choose a membership level and pay the monthly or annual fee with your credit card (orother available payment methods in your country)."},
            {id:3, listcontent:"Your membership fee is converted into RXHEAL tokens."},
            {id:4, listcontent:"Schedule your first health coaching consultation or group class."},
            {id:5, listcontent:"Watch your token rewards increase inside your dashboard. The more you invest in your health by following your coaches guidance the more rewards you will accumulate)."},
            {id:6, listcontent:"You may join the Longevity Club (lock your token for 12-months for additional rewards)."},
        ], 
        3: [
            {id:1, listcontent:"Register Z either through the link provided by your coach or at www.healthiwealthi.io (usingour secure mobile phone verification)."},
            {id:2, listcontent:"Choose a membership level and pay the monthly or annual fee with your credit card (orother available payment methods in your country)."},
            {id:3, listcontent:"Your membership fee is converted into RXHEAL tokens."},
            {id:4, listcontent:"Schedule your first health coaching consultation or group class."},
            {id:5, listcontent:"Watch your token rewards increase inside your dashboard. The more you invest in your health by following your coaches guidance the more rewards you will accumulate)."},
            {id:6, listcontent:"You may join the Longevity Club (lock your token for 12-months for additional rewards)."},
        ],
        4:[
            {id:1, listcontent:"Register Z either through the link provided by your coach or at www.healthiwealthi.io (usingour secure mobile phone verification)."},
            {id:2, listcontent:"Choose a membership level and pay the monthly or annual fee with your credit card (orother available payment methods in your country)."},
            {id:3, listcontent:"Your membership fee is converted into RXHEAL tokens."},
            {id:4, listcontent:"Schedule your first health coaching consultation or group class."},
            {id:5, listcontent:"Watch your token rewards increase inside your dashboard. The more you invest in your health by following your coaches guidance the more rewards you will accumulate)."},
            {id:6, listcontent:"You may join the Longevity Club (lock your token for 12-months for additional rewards)."},
        ],
        5:[
            {id:1, listcontent:"Register Z either through the link provided by your coach or at www.healthiwealthi.io (usingour secure mobile phone verification)."},
            {id:2, listcontent:"Choose a membership level and pay the monthly or annual fee with your credit card (orother available payment methods in your country)."},
            {id:3, listcontent:"Your membership fee is converted into RXHEAL tokens."},
            {id:4, listcontent:"Schedule your first health coaching consultation or group class."},
            {id:5, listcontent:"Watch your token rewards increase inside your dashboard. The more you invest in your health by following your coaches guidance the more rewards you will accumulate)."},
            {id:6, listcontent:"You may join the Longevity Club (lock your token for 12-months for additional rewards)."},
        ],
        6:[
            {id:1, listcontent:"Register Z either through the link provided by your coach or at www.healthiwealthi.io (usingour secure mobile phone verification)."},
            {id:2, listcontent:"Choose a membership level and pay the monthly or annual fee with your credit card (orother available payment methods in your country)."},
            {id:3, listcontent:"Your membership fee is converted into RXHEAL tokens."},
            {id:4, listcontent:"Schedule your first health coaching consultation or group class."},
            {id:5, listcontent:"Watch your token rewards increase inside your dashboard. The more you invest in your health by following your coaches guidance the more rewards you will accumulate)."},
            {id:6, listcontent:"You may join the Longevity Club (lock your token for 12-months for additional rewards)."},
        ],
        7:[
            {id:1, listcontent:"Register Z either through the link provided by your coach or at www.healthiwealthi.io (usingour secure mobile phone verification)."},
            {id:2, listcontent:"Choose a membership level and pay the monthly or annual fee with your credit card (orother available payment methods in your country)."},
            {id:3, listcontent:"Your membership fee is converted into RXHEAL tokens."},
            {id:4, listcontent:"Schedule your first health coaching consultation or group class."},
            {id:5, listcontent:"Watch your token rewards increase inside your dashboard. The more you invest in your health by following your coaches guidance the more rewards you will accumulate)."},
            {id:6, listcontent:"You may join the Longevity Club (lock your token for 12-months for additional rewards)."},
        ],
        8:[
            {id:1, listcontent:"Register Z either through the link provided by your coach or at www.healthiwealthi.io (usingour secure mobile phone verification)."},
            {id:2, listcontent:"Choose a membership level and pay the monthly or annual fee with your credit card (orother available payment methods in your country)."},
            {id:3, listcontent:"Your membership fee is converted into RXHEAL tokens."},
            {id:4, listcontent:"Schedule your first health coaching consultation or group class."},
            {id:5, listcontent:"Watch your token rewards increase inside your dashboard. The more you invest in your health by following your coaches guidance the more rewards you will accumulate)."},
            {id:6, listcontent:"You may join the Longevity Club (lock your token for 12-months for additional rewards)."},
        ],
        9:[
            {id:1, listcontent:"Register Z either through the link provided by your coach or at www.healthiwealthi.io (usingour secure mobile phone verification)."},
            {id:2, listcontent:"Choose a membership level and pay the monthly or annual fee with your credit card (orother available payment methods in your country)."},
            {id:3, listcontent:"Your membership fee is converted into RXHEAL tokens."},
            {id:4, listcontent:"Schedule your first health coaching consultation or group class."},
            {id:5, listcontent:"Watch your token rewards increase inside your dashboard. The more you invest in your health by following your coaches guidance the more rewards you will accumulate)."},
            {id:6, listcontent:"You may join the Longevity Club (lock your token for 12-months for additional rewards)."},
        ],
        10:[
            {id:1, listcontent:"Register Z either through the link provided by your coach or at www.healthiwealthi.io (usingour secure mobile phone verification)."},
            {id:2, listcontent:"Choose a membership level and pay the monthly or annual fee with your credit card (orother available payment methods in your country)."},
            {id:3, listcontent:"Your membership fee is converted into RXHEAL tokens."},
            {id:4, listcontent:"Schedule your first health coaching consultation or group class."},
            {id:5, listcontent:"Watch your token rewards increase inside your dashboard. The more you invest in your health by following your coaches guidance the more rewards you will accumulate)."},
            {id:6, listcontent:"You may join the Longevity Club (lock your token for 12-months for additional rewards)."},
        ],
        11:[
            {id:1, listcontent:"Register Z either through the link provided by your coach or at www.healthiwealthi.io (usingour secure mobile phone verification)."},
            {id:2, listcontent:"Choose a membership level and pay the monthly or annual fee with your credit card (orother available payment methods in your country)."},
            {id:3, listcontent:"Your membership fee is converted into RXHEAL tokens."},
            {id:4, listcontent:"Schedule your first health coaching consultation or group class."},
            {id:5, listcontent:"Watch your token rewards increase inside your dashboard. The more you invest in your health by following your coaches guidance the more rewards you will accumulate)."},
            {id:6, listcontent:"You may join the Longevity Club (lock your token for 12-months for additional rewards)."},
        ],
        12:[
            {id:1, listcontent:"Register Z either through the link provided by your coach or at www.healthiwealthi.io (usingour secure mobile phone verification)."},
            {id:2, listcontent:"Choose a membership level and pay the monthly or annual fee with your credit card (orother available payment methods in your country)."},
            {id:3, listcontent:"Your membership fee is converted into RXHEAL tokens."},
            {id:4, listcontent:"Schedule your first health coaching consultation or group class."},
            {id:5, listcontent:"Watch your token rewards increase inside your dashboard. The more you invest in your health by following your coaches guidance the more rewards you will accumulate)."},
            {id:6, listcontent:"You may join the Longevity Club (lock your token for 12-months for additional rewards)."},
        ]
    }
    return (
        <section className="frequently-asked-questions">
            <Container>
                <Row>
                    <Col md={12}>
                        <div className="content">
                            <h3>Frequently Asked Questions</h3>
                        </div>
                        <div className="accordion custom-accordion accordion-flush" id="accordionFlushExample">
                            {CustomAccordion.map((i)=>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id={"flush-heading"+i.id}>
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#flush-collapse"+i.id} aria-expanded="false" aria-controls="flush-collapseOne">
                                        {i.headingContent}
                                    </button>
                                </h2>
                                <div id={"flush-collapse"+i.id} className="accordion-collapse collapse" aria-labelledby={"flush-heading"+i.id} data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                        <ul>
                                            {CustomList[i.parent].map((i2)=>
                                            <li>{i2.listcontent}</li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            )}
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};
export default Questions;

import React, { Component } from "react";
import Slider from "react-slick";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../team/team.css";
import leftArrow from '../../images/left-arrow.svg';
import rightArrow from '../../images/right-arrow.svg';
import { Col, Container, Row } from "react-bootstrap";
import memberOne from "../../images/member-1.svg";
import memberTwo from "../../images/member-2.svg";
import memberThree from "../../images/member-3.svg";
import memberFour from "../../images/member-4.svg";
import memberFive from "../../images/member-5.svg";
class MyTeam extends React.Component{
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
render() {
  var settings = {
    arrows:false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 2,
    initialSlide: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
          arrows:false,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          arrows:false,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          arrows:false,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          arrows:false,
        }
      }
    ]
  };
  let TeamElements = [
    { id: 1, image: memberOne, name: "Johannes R. Fisslinger,", field: "Founder & CEO" },
    { id: 2, image: memberTwo, name: "Isaac Hall,", field: "DAO Consultan" },
    { id: 3, image: memberThree, name: "Danish Hameed", field: "Blockchain Consultant" },
    { id: 4, image: memberFour, name: "Alessandro Fisslinger", field: "Blockchain Project Manager" },
    { id: 5, image: memberFive, name: "Annie Gedye", field: "B.Sc., LPHCS, Coach Support, Trainer" },
    { id: 6, image: memberOne, name: "Johannes R. Fisslinger,", field: "Founder & CEO" },
    { id: 7, image: memberTwo, name: "Isaac Hall,", field: "DAO Consultan" },
    { id: 8, image: memberThree, name: "Danish Hameed", field: "Blockchain Consultant" },
    { id: 9, image: memberFour, name: "Alessandro Fisslinger", field: "Blockchain Project Manager" },
    { id: 10, image: memberFive, name: "Annie Gedye", field: "B.Sc., LPHCS, Coach Support, Trainer" },
  ]
return (
  <>
    <section className="team-gallery">
      <Container fluid className="custom-container">
        <Row>
          <Col md={12}>
            <div className="content">
              <h4>{this.props.heading}</h4>
              <p>
                {this.props.paragraph}
              </p>
            </div>
          </Col>
          <Slider ref={c => (this.slider = c)}  {...settings} className="product-slider">
            {TeamElements.map((e) =>
              <figure className="figure team-members">
                <img className="product-image img-fluid" src={e.image} alt="image" />
                <figcaption className="figure-caption">
                  <h2>{e.name}</h2>
                  <p>{e.field}</p>
                </figcaption>
              </figure>
            )}
          </Slider>
          <div style={{textAlign:"center"}}>
          <div className=" custom-prev" onClick={this.previous}>
          <span class='arrows'><img src={leftArrow} alt='image'/></span>
          </div>
          <div className=" custom-next" onClick={this.next}>
          <span class='arrows'><img src={rightArrow}alt='image'/></span>
          </div>
        </div>
        </Row>
      </Container>
    </section>
  </>
);
};
};
export default MyTeam;

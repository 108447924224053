import React from "react";
import {  Container, Row } from "react-bootstrap";
import '../banner/banner.css';
function Banner(props) {
  return (
    <div>
      <section className="banner">
        <Container fluid className="custom-container">
          <Row>
          <div class="banner-content">
            <h6>Invest in What’s Important</h6>
            <h2>POWERED BY THE REVOLUTIONARY <span>RxHEAL BLOCKCHAIN TOKEN.</span></h2>
            <p>RXHEAL powers the wellbeing and prosperity-generating, peer-to-peer decentralized Healthcare 3.0 membership platform and ecosystem that rewards practitioners and members for living a healthier, happier, and longer life.</p>
         <a href="/login">  <button  class="btn btn-danger">INVEST & JOIN</button>  </a>          
         </div>
          </Row>
        </Container>
      </section>
    </div>
  );
}

export default Banner;

import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../erorr/erorr.css";

function Tokenomics(props) {
  return (
    <div>
      <Container>
        <Row>
          <Col md={12}>
            <div className="page-not-found">
              <h5>Coming Soon</h5>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Tokenomics;

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.min.js';
import { Route, Switch } from "react-router-dom";
import './App.css';
import Erorr from "./components/erorr/Erorr";
import WhitePaper from "./components/white-paper/WhitePaper";
// import Pdf from "../components/pdf/HealthiWealthi_White_Paper_2022";
import Roadmap from './components/roadmap/Roadmap';
import Tokensale from './components/token-sale/Tokensale';
import Tokenomics from './components/tokenomics/Tokeomics';
import About from './components/about/About';
import Contact from './components/contact/Contact';
import Home from './components/home/Home';
import Footer from './components/footer/Footer';
import Navigation from './components/navbar/Navbar';
function App() {
  return (
    <div className="App">
        <Navigation/>
      <Switch>
        <Route path="/" component={Home}/>
        <Route path="/whitepaper" component={WhitePaper}/>
        <Route path="/roadmap" component={Roadmap}/>
        <Route path="/tokensale" component={Tokensale}/>
        <Route path="/tokenomics" component={Tokenomics}/>
        <Route path="/about" component={About}/>
        <Route path="/contact" component={Contact}/>
        {/* <Route path="/Pdf" component={Pdf}/> */}

        
        <Route  component={Erorr}/>
      </Switch>
      <Footer/>
    </div>
  );
}

export default App;

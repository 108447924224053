import React from 'react';
import '../sponser/sponsers.css';
import { Container,Row } from 'react-bootstrap';
import CompanyOne from '../../images/b1.jpg';
import CompanyTwo from '../../images/b2.jpg';
import CompanyThree from '../../images/b3.jpg';
import CompanyFour from '../../images/b4.jpg';
import CompanyFive from '../../images/b5.jpg';

const Vendors = () => {
    const Sponser  = [
        {id :1, image: CompanyOne,},
        {id :2, image: CompanyTwo,},
        {id :3, image: CompanyThree,},
        {id :4, image: CompanyFour,},
        {id :5, image: CompanyFive,}
]
    return (
        <div className='sponser'>
           <Container fluid className='custom-container'>
        <Row>
            <div className='sponser-img'>
            {Sponser.map((i) =>
                 <img src={i.image} alt="images" className="img-fluid"/>
                 )}
            </div>
        </Row>
           </Container>
        </div>
    )
}

export default Vendors;

import React from "react";
import "../budget-allocation/budget-allocation.css";
import { Col, Container, Row } from "react-bootstrap";
import graphImage from "../../images/Graph.svg";
import rxhlImage from "../../images/RxHL.svg";
const BudgetAllocation = (props) => {
    const EvualtionCard =[
        {id:1,paragraph:"Development & Engineering",percentagevalue:"35%"},
        {id:2,paragraph:"Marketing",percentagevalue:"20%"},
        {id:3,paragraph:"Operations",percentagevalue:"15%"},
        {id:4,paragraph:"Business Readiness",percentagevalue:"10%"},
        {id:5,paragraph:"Legal",percentagevalue:"5%"},
        {id:6,paragraph:"Reserves",percentagevalue:"15%"},
    ]
  return (
    <section className="budget-allocation">
      <Container fluid className="custom-container">
        <Row>
          <Col lg={6} md={12} className=" order-sm-first order-last">
            <div className="graph">
              <div className="overlay">
                <img src={rxhlImage} className="img-fluid" alt="image" />
              </div>
              <div className="token-allocation-image budget-allocation-image text-center">
                <img src={graphImage} className="img-fluid" alt="image" />
              </div>
            </div>
          </Col>
          <Col lg={6} md={12}>
            <h4>{props.heading}</h4>
            <Row>
            {EvualtionCard.map((i) => 
              <Col md={4}>
                <div className="percentage-card">   
                <span className={"dot dot-"+i.id}></span>
                  <p>{i.paragraph}</p>
                  <h3 className="percentage-value-1">{i.percentagevalue}</h3>
                </div>
              </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default BudgetAllocation;

import React from "react";
import "../membership-level/membershiplevel.css";
import { Col, Container, Row } from "react-bootstrap";

const MemberShipLevel = () => {
    const MemebershipCard =[
        {
            id:1,
            title:"Health Coaches & Practitioners",
            text:" Health providers are the main contributors & proactive drivers of the platform. They are paid for services provided tomembers (clients) and earn RXHEAL tokens and rewards."
        },
        {
            id:2,
            title:"Personal & Family Memberships",
            text:" Individuals and families will receive RXHEAL rewards for group and personal consultations, engaging with their personal coach, and improving their lifestyle habits."
        },
        {
            id:3,
            title:"Teams",
            text:" HealthiWealthi™ provides doctors, clinics, hospitals, health centers, & corporations an easy-to-integrate way to add preventive & health coaching services without the need to hire expensive staff or change their workflow."
        },
        {
            id:4,
            title:"School Teachers",
            text:"School teachers join our platform to teach kids and youth how to live healthy and wealthy by practicing conscious, empowering lifestyle habits."
        }
    ]
  return (
    <section className="membership-level">
      <Container fluid className="custom-container">
        <Row>
          <div class="content text-center">
            <h3>
              Think <span>"Spotify®"</span> For Healthy Living
            </h3>
          </div>
          {MemebershipCard.map((i) =>
          <Col className="mb-5" md={6}>
            <div class="card custom-card ">
              <div class="card-body">
                <h5 class="card-title">{i.title}</h5>
                <p class="card-text">{i.text}</p>
              </div>
            </div>
          </Col>
           )}
        </Row>
      </Container>
    </section>
  );
};

export default MemberShipLevel;

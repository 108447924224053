import React ,{useEffect} from "react";
import "../big-plan/bigplan.css";
import { Col, Container, Row } from "react-bootstrap";

const AnimatedHeading = (props) => {
  return (
    <section className="big-plan">
      <Container fluid className="custom-container">
        <Row>
          <Col md={12}>
            <div class="content text-center">
            <h3 class="anme-2">{props.children}</h3>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AnimatedHeading;

import React from "react";
import "../ecosystem/ecosystem.css";
import { Col, Container, Row } from "react-bootstrap";
const EcoGraph = () => {
  return (
    <>
      <section className="experties" id="roadmap">
        <Container fluid className="custom-container" id="">
          <Row>
            <Col md={12}>
              <p>
                Our team of expert developers, researchers, medical
                advisors,contributors, and members are achieving these
                milestones toimprove and expand our platform and ecosystem.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="ecosytem">
        <Container fluid className="custom-container">
          <Row>
            <Col md={12}>
              <div className="d-flex flex-row bd-highlight mb-3 ecosytem-area">
                <div className="statics">
                  <div className="dates date-1 text-center dat-1">
                    December 2023
                  </div>
                  <div className="dot-area">
                    <div className="dots d-flex justify-content-center">
                      <div className="dotss circle-1"></div>
                    </div>
                  </div>
                  <div className="about para-1">
                    Project was downloaded from the 'matrix' ⚡
                  </div>
                </div>
                <div className="statics">
                  <div className="about-1 para-2">
                    Platform Design and Structure finalized
                  </div>
                  <div className="dot-area">
                    <div className="dots d-flex justify-content-center">
                      <div className="dotss circle-2"></div>
                    </div>
                    <div className="dates-1 date-2 pt-3 text-center dat-2">
                      February 2022
                    </div>
                  </div>
                </div>
                <div className="statics">
                  <div className="dates date-1 text-center dat-3">
                    March 2022
                  </div>
                  <div className="dot-area">
                    <div className="dots d-flex justify-content-center">
                      <div className="dotss circle-3"></div>
                    </div>
                  </div>
                  <div className="about para-3">
                    Pre-launch and supporter website with wallet completed
                  </div>
                </div>
                <div className="statics">
                  <div className="about-1 para-4">
                    Platform app front & backend (Version 1.0) completed
                  </div>
                  <div className="dot-area">
                    <div className="dots d-flex justify-content-center">
                      <div className="dotss circle-4"></div>
                    </div>
                    <div className="dates-1 date-2 pt-3 text-center dat-4">
                      June 2022
                    </div>
                  </div>
                </div>
                <div className="statics">
                  <div className="dates date-1 text-center dat-5">July2022</div>
                  <div className="dot-area">
                    <div className="dots d-flex justify-content-center">
                      <div className="dotss circle-5"></div>
                    </div>
                  </div>
                  <div className="about para-5">
                    Research (beta-test) with 500 members & 10 coaches.
                  </div>
                </div>
                <div className="d-flex align-items-end position-relative platform-design">
                  <div className="custom-about">
                    Private Investors Token Sale starts
                  </div>
                </div>
                <div className="statics">
                  <div className="about-1 para-6">
                    Pre-Launch RXHEAL Airdrop (1 Million Token Give-Away)
                  </div>
                  <div className="dot-area">
                    <div className="dots d-flex justify-content-center">
                      <div className="dotss circle-2"></div>
                    </div>
                    <div className="dates-1 date-2 pt-3 text-center dat-6">
                      October 2022
                    </div>
                  </div>
                </div>
                <div className="statics">
                  <div className="dates date-1 text-center dat-7">
                    November 2022
                  </div>
                  <div className="dot-area">
                    <div className="dots d-flex justify-content-center">
                      <div className="dotss circle-3"></div>
                    </div>
                  </div>
                  <div className="about para-7">
                    Official Public Platform and Token Launch
                  </div>
                </div>
                <div className="statics">
                  <div className="about-1 para-8">
                    Large Teams and Organizations join platform
                  </div>
                  <div className="dot-area">
                    <div className="dots d-flex justify-content-center">
                      <div className="dotss circle-4"></div>
                    </div>
                    <div className="dates-1 date-2 pt-3 text-center dat-8">
                      February 2023
                    </div>
                  </div>
                </div>
                <div className="statics">
                  <div className="about-1 para-9">
                    School Teachers join platform
                  </div>
                  <div className="dot-area">
                    <div className="dots d-flex justify-content-center">
                      <div className="dotss circle-9"></div>
                    </div>
                    <div className="dates-1 date-2 pt-3 text-center dat-9">
                      September 2023
                    </div>
                  </div>
                </div>
                <div className="statics">
                  <div className="dates date-1 text-center dat-10">
                    December 2023
                  </div>
                  <div className="dot-area">
                    <div className="dots d-flex justify-content-center">
                      <div className="dotss circle-1"></div>
                    </div>
                  </div>
                  <div className="about para-10">
                    Platform opens to developers
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default EcoGraph;
